.react-flow {
  --xy-edge-stroke-default: #b1b1b7;
  --xy-edge-stroke-width-default: 1;
  --xy-edge-stroke-selected-default: #555;
  --xy-connectionline-stroke-default: #b1b1b7;
  --xy-connectionline-stroke-width-default: 1;
  --xy-attribution-background-color-default: #ffffff80;
  --xy-minimap-background-color-default: #fff;
  --xy-minimap-mask-background-color-default: #f0f0f099;
  --xy-minimap-mask-stroke-color-default: transparent;
  --xy-minimap-mask-stroke-width-default: 1;
  --xy-minimap-node-background-color-default: #e2e2e2;
  --xy-minimap-node-stroke-color-default: transparent;
  --xy-minimap-node-stroke-width-default: 2;
  --xy-background-color-default: transparent;
  --xy-background-pattern-dots-color-default: #91919a;
  --xy-background-pattern-lines-color-default: #eee;
  --xy-background-pattern-cross-color-default: #e2e2e2;
  background-color: var(--xy-background-color, var(--xy-background-color-default));
  --xy-node-color-default: inherit;
  --xy-node-border-default: 1px solid #1a192b;
  --xy-node-background-color-default: #fff;
  --xy-node-group-background-color-default: #f0f0f040;
  --xy-node-boxshadow-hover-default: 0 1px 4px 1px #00000014;
  --xy-node-boxshadow-selected-default: 0 0 0 .5px #1a192b;
  --xy-node-border-radius-default: 3px;
  --xy-handle-background-color-default: #1a192b;
  --xy-handle-border-color-default: #fff;
  --xy-selection-background-color-default: #0059dc14;
  --xy-selection-border-default: 1px dotted #0059dccc;
  --xy-controls-button-background-color-default: #fefefe;
  --xy-controls-button-background-color-hover-default: #f4f4f4;
  --xy-controls-button-color-default: inherit;
  --xy-controls-button-color-hover-default: inherit;
  --xy-controls-button-border-color-default: #eee;
  --xy-controls-box-shadow-default: 0 0 2px 1px #00000014;
  --xy-edge-label-background-color-default: #fff;
  --xy-edge-label-color-default: inherit;
  --xy-resize-background-color-default: #3367d9;
  direction: ltr;
}

.react-flow.dark {
  --xy-edge-stroke-default: #3e3e3e;
  --xy-edge-stroke-width-default: 1;
  --xy-edge-stroke-selected-default: #727272;
  --xy-connectionline-stroke-default: #b1b1b7;
  --xy-connectionline-stroke-width-default: 1;
  --xy-attribution-background-color-default: #96969640;
  --xy-minimap-background-color-default: #141414;
  --xy-minimap-mask-background-color-default: #3c3c3c99;
  --xy-minimap-mask-stroke-color-default: transparent;
  --xy-minimap-mask-stroke-width-default: 1;
  --xy-minimap-node-background-color-default: #2b2b2b;
  --xy-minimap-node-stroke-color-default: transparent;
  --xy-minimap-node-stroke-width-default: 2;
  --xy-background-color-default: #141414;
  --xy-background-pattern-dots-color-default: #777;
  --xy-background-pattern-lines-color-default: #777;
  --xy-background-pattern-cross-color-default: #777;
  --xy-node-color-default: #f8f8f8;
  --xy-node-border-default: 1px solid #3c3c3c;
  --xy-node-background-color-default: #1e1e1e;
  --xy-node-group-background-color-default: #f0f0f040;
  --xy-node-boxshadow-hover-default: 0 1px 4px 1px #ffffff14;
  --xy-node-boxshadow-selected-default: 0 0 0 .5px #999;
  --xy-handle-background-color-default: #bebebe;
  --xy-handle-border-color-default: #1e1e1e;
  --xy-selection-background-color-default: #c8c8dc14;
  --xy-selection-border-default: 1px dotted #c8c8dccc;
  --xy-controls-button-background-color-default: #2b2b2b;
  --xy-controls-button-background-color-hover-default: #3e3e3e;
  --xy-controls-button-color-default: #f8f8f8;
  --xy-controls-button-color-hover-default: #fff;
  --xy-controls-button-border-color-default: #5b5b5b;
  --xy-controls-box-shadow-default: 0 0 2px 1px #00000014;
  --xy-edge-label-background-color-default: #141414;
  --xy-edge-label-color-default: #f8f8f8;
}

.react-flow__background {
  background-color: var(--xy-background-color, var(--xy-background-color-props, var(--xy-background-color-default)));
  pointer-events: none;
  z-index: -1;
}

.react-flow__container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.react-flow__pane {
  z-index: 1;
}

.react-flow__pane.draggable {
  cursor: grab;
}

.react-flow__pane.dragging {
  cursor: grabbing;
}

.react-flow__pane.selection {
  cursor: pointer;
}

.react-flow__viewport {
  transform-origin: 0 0;
  z-index: 2;
  pointer-events: none;
}

.react-flow__renderer {
  z-index: 4;
}

.react-flow__selection {
  z-index: 6;
}

.react-flow__nodesselection-rect:focus, .react-flow__nodesselection-rect:focus-visible {
  outline: none;
}

.react-flow__edge-path {
  stroke: var(--xy-edge-stroke, var(--xy-edge-stroke-default));
  stroke-width: var(--xy-edge-stroke-width, var(--xy-edge-stroke-width-default));
  fill: none;
}

.react-flow__connection-path {
  stroke: var(--xy-connectionline-stroke, var(--xy-connectionline-stroke-default));
  stroke-width: var(--xy-connectionline-stroke-width, var(--xy-connectionline-stroke-width-default));
  fill: none;
}

.react-flow .react-flow__edges {
  position: absolute;
}

.react-flow .react-flow__edges svg {
  pointer-events: none;
  position: absolute;
  overflow: visible;
}

.react-flow__edge {
  pointer-events: visibleStroke;
}

.react-flow__edge.selectable {
  cursor: pointer;
}

.react-flow__edge.animated path {
  stroke-dasharray: 5;
  animation: .5s linear infinite dashdraw;
}

.react-flow__edge.animated path.react-flow__edge-interaction {
  stroke-dasharray: none;
  animation: none;
}

.react-flow__edge.inactive {
  pointer-events: none;
}

.react-flow__edge.selected, .react-flow__edge:focus, .react-flow__edge:focus-visible {
  outline: none;
}

.react-flow__edge.selected .react-flow__edge-path, .react-flow__edge.selectable:focus .react-flow__edge-path, .react-flow__edge.selectable:focus-visible .react-flow__edge-path {
  stroke: var(--xy-edge-stroke-selected, var(--xy-edge-stroke-selected-default));
}

.react-flow__edge-textwrapper {
  pointer-events: all;
}

.react-flow__edge .react-flow__edge-text {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.react-flow__connection {
  pointer-events: none;
}

.react-flow__connection .animated {
  stroke-dasharray: 5;
  animation: .5s linear infinite dashdraw;
}

svg.react-flow__connectionline {
  z-index: 1001;
  position: absolute;
  overflow: visible;
}

.react-flow__nodes {
  pointer-events: none;
  transform-origin: 0 0;
}

.react-flow__node {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: all;
  transform-origin: 0 0;
  box-sizing: border-box;
  cursor: default;
  position: absolute;
}

.react-flow__node.selectable {
  cursor: pointer;
}

.react-flow__node.draggable {
  cursor: grab;
  pointer-events: all;
}

.react-flow__node.draggable.dragging {
  cursor: grabbing;
}

.react-flow__nodesselection {
  z-index: 3;
  transform-origin: 0 0;
  pointer-events: none;
}

.react-flow__nodesselection-rect {
  pointer-events: all;
  cursor: grab;
  position: absolute;
}

.react-flow__handle {
  pointer-events: none;
  background-color: var(--xy-handle-background-color, var(--xy-handle-background-color-default));
  border: 1px solid var(--xy-handle-border-color, var(--xy-handle-border-color-default));
  border-radius: 100%;
  width: 6px;
  min-width: 5px;
  height: 6px;
  min-height: 5px;
  position: absolute;
}

.react-flow__handle.connectingfrom {
  pointer-events: all;
}

.react-flow__handle.connectionindicator {
  pointer-events: all;
  cursor: crosshair;
}

.react-flow__handle-bottom {
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.react-flow__handle-top {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-flow__handle-left {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.react-flow__handle-right {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.react-flow__edgeupdater {
  cursor: move;
  pointer-events: all;
}

.react-flow__panel {
  z-index: 5;
  margin: 15px;
  position: absolute;
}

.react-flow__panel.top {
  top: 0;
}

.react-flow__panel.bottom {
  bottom: 0;
}

.react-flow__panel.left {
  left: 0;
}

.react-flow__panel.right {
  right: 0;
}

.react-flow__panel.center {
  left: 50%;
  transform: translateX(-50%);
}

.react-flow__attribution {
  background: var(--xy-attribution-background-color, var(--xy-attribution-background-color-default));
  margin: 0;
  padding: 2px 3px;
  font-size: 10px;
}

.react-flow__attribution a {
  color: #999;
  text-decoration: none;
}

@keyframes dashdraw {
  from {
    stroke-dashoffset: 10px;
  }
}

.react-flow__edgelabel-renderer {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.react-flow__viewport-portal {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.react-flow__minimap {
  background: var(--xy-minimap-background-color-props, var(--xy-minimap-background-color, var(--xy-minimap-background-color-default)));
}

.react-flow__minimap-svg {
  display: block;
}

.react-flow__minimap-mask {
  fill: var(--xy-minimap-mask-background-color-props, var(--xy-minimap-mask-background-color, var(--xy-minimap-mask-background-color-default)));
  stroke: var(--xy-minimap-mask-stroke-color-props, var(--xy-minimap-mask-stroke-color, var(--xy-minimap-mask-stroke-color-default)));
  stroke-width: var(--xy-minimap-mask-stroke-width-props, var(--xy-minimap-mask-stroke-width, var(--xy-minimap-mask-stroke-width-default)));
}

.react-flow__minimap-node {
  fill: var(--xy-minimap-node-background-color-props, var(--xy-minimap-node-background-color, var(--xy-minimap-node-background-color-default)));
  stroke: var(--xy-minimap-node-stroke-color-props, var(--xy-minimap-node-stroke-color, var(--xy-minimap-node-stroke-color-default)));
  stroke-width: var(--xy-minimap-node-stroke-width-props, var(--xy-minimap-node-stroke-width, var(--xy-minimap-node-stroke-width-default)));
}

.react-flow__background-pattern.dots {
  fill: var(--xy-background-pattern-color-props, var(--xy-background-pattern-color, var(--xy-background-pattern-dots-color-default)));
}

.react-flow__background-pattern.lines {
  stroke: var(--xy-background-pattern-color-props, var(--xy-background-pattern-color, var(--xy-background-pattern-lines-color-default)));
}

.react-flow__background-pattern.cross {
  stroke: var(--xy-background-pattern-color-props, var(--xy-background-pattern-color, var(--xy-background-pattern-cross-color-default)));
}

.react-flow__controls {
  box-shadow: var(--xy-controls-box-shadow, var(--xy-controls-box-shadow-default));
  flex-direction: column;
  display: flex;
}

.react-flow__controls.horizontal {
  flex-direction: row;
}

.react-flow__controls-button {
  background: var(--xy-controls-button-background-color, var(--xy-controls-button-background-color-default));
  border: none;
  border-bottom: 1px solid var(--xy-controls-button-border-color-props, var(--xy-controls-button-border-color, var(--xy-controls-button-border-color-default)));
  color: var(--xy-controls-button-color-props, var(--xy-controls-button-color, var(--xy-controls-button-color-default)));
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  padding: 4px;
  display: flex;
}

.react-flow__controls-button svg {
  fill: currentColor;
  width: 100%;
  max-width: 12px;
  max-height: 12px;
}

.react-flow__edge.updating .react-flow__edge-path {
  stroke: #777;
}

.react-flow__edge-text {
  font-size: 10px;
}

.react-flow__node.selectable:focus, .react-flow__node.selectable:focus-visible {
  outline: none;
}

.react-flow__node-input, .react-flow__node-default, .react-flow__node-output, .react-flow__node-group {
  border-radius: var(--xy-node-border-radius, var(--xy-node-border-radius-default));
  color: var(--xy-node-color, var(--xy-node-color-default));
  text-align: center;
  border: var(--xy-node-border, var(--xy-node-border-default));
  background-color: var(--xy-node-background-color, var(--xy-node-background-color-default));
  width: 150px;
  padding: 10px;
  font-size: 12px;
}

.react-flow__node-input.selectable:hover, .react-flow__node-default.selectable:hover, .react-flow__node-output.selectable:hover, .react-flow__node-group.selectable:hover {
  box-shadow: var(--xy-node-boxshadow-hover, var(--xy-node-boxshadow-hover-default));
}

.react-flow__node-input.selectable.selected, .react-flow__node-input.selectable:focus, .react-flow__node-input.selectable:focus-visible, .react-flow__node-default.selectable.selected, .react-flow__node-default.selectable:focus, .react-flow__node-default.selectable:focus-visible, .react-flow__node-output.selectable.selected, .react-flow__node-output.selectable:focus, .react-flow__node-output.selectable:focus-visible, .react-flow__node-group.selectable.selected, .react-flow__node-group.selectable:focus, .react-flow__node-group.selectable:focus-visible {
  box-shadow: var(--xy-node-boxshadow-selected, var(--xy-node-boxshadow-selected-default));
}

.react-flow__node-group {
  background-color: var(--xy-node-group-background-color, var(--xy-node-group-background-color-default));
}

.react-flow__nodesselection-rect, .react-flow__selection {
  background: var(--xy-selection-background-color, var(--xy-selection-background-color-default));
  border: var(--xy-selection-border, var(--xy-selection-border-default));
}

.react-flow__nodesselection-rect:focus, .react-flow__nodesselection-rect:focus-visible, .react-flow__selection:focus, .react-flow__selection:focus-visible {
  outline: none;
}

.react-flow__controls-button:hover {
  background: var(--xy-controls-button-background-color-hover-props, var(--xy-controls-button-background-color-hover, var(--xy-controls-button-background-color-hover-default)));
  color: var(--xy-controls-button-color-hover-props, var(--xy-controls-button-color-hover, var(--xy-controls-button-color-hover-default)));
}

.react-flow__controls-button:disabled {
  pointer-events: none;
}

.react-flow__controls-button:disabled svg {
  fill-opacity: .4;
}

.react-flow__controls-button:last-child {
  border-bottom: none;
}

.react-flow__resize-control {
  position: absolute;
}

.react-flow__resize-control.left, .react-flow__resize-control.right {
  cursor: ew-resize;
}

.react-flow__resize-control.top, .react-flow__resize-control.bottom {
  cursor: ns-resize;
}

.react-flow__resize-control.top.left, .react-flow__resize-control.bottom.right {
  cursor: nwse-resize;
}

.react-flow__resize-control.bottom.left, .react-flow__resize-control.top.right {
  cursor: nesw-resize;
}

.react-flow__resize-control.handle {
  background-color: var(--xy-resize-background-color, var(--xy-resize-background-color-default));
  border: 1px solid #fff;
  border-radius: 1px;
  width: 4px;
  height: 4px;
  transform: translate(-50%, -50%);
}

.react-flow__resize-control.handle.left {
  top: 50%;
  left: 0;
}

.react-flow__resize-control.handle.right {
  top: 50%;
  left: 100%;
}

.react-flow__resize-control.handle.top {
  top: 0;
  left: 50%;
}

.react-flow__resize-control.handle.bottom {
  top: 100%;
  left: 50%;
}

.react-flow__resize-control.handle.top.left, .react-flow__resize-control.handle.bottom.left {
  left: 0;
}

.react-flow__resize-control.handle.top.right, .react-flow__resize-control.handle.bottom.right {
  left: 100%;
}

.react-flow__resize-control.line {
  border-color: var(--xy-resize-background-color, var(--xy-resize-background-color-default));
  border-style: solid;
  border-width: 0;
}

.react-flow__resize-control.line.left, .react-flow__resize-control.line.right {
  width: 1px;
  height: 100%;
  top: 0;
  transform: translate(-50%);
}

.react-flow__resize-control.line.left {
  border-left-width: 1px;
  left: 0;
}

.react-flow__resize-control.line.right {
  border-right-width: 1px;
  left: 100%;
}

.react-flow__resize-control.line.top, .react-flow__resize-control.line.bottom {
  width: 100%;
  height: 1px;
  left: 0;
  transform: translate(0, -50%);
}

.react-flow__resize-control.line.top {
  border-top-width: 1px;
  top: 0;
}

.react-flow__resize-control.line.bottom {
  border-bottom-width: 1px;
  top: 100%;
}

.react-flow__edge-textbg {
  fill: var(--xy-edge-label-background-color, var(--xy-edge-label-background-color-default));
}

.react-flow__edge-text {
  fill: var(--xy-edge-label-color, var(--xy-edge-label-color-default));
}
/*# sourceMappingURL=index.e7a39c2b.css.map */
